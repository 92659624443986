* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    color: rgb(189, 39, 39);
    font-weight: bolder;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #222;
    /* padding-bottom: 10px; */
}

.home-page {
    padding: 22px 0px;
}

.container {
    max-width: 1200px;
    /* margin: 0 auto; */
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
}

.hero {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../imgs/background_trans.jpg') no-repeat center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    height: 100vh;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: -2;

}

.hero.container.background-imagine {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

/* .hero .container .footer {
    text-align: center;
} */

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.549); */
    z-index: -1;
}

.hero h1 {
    font-size: 50px;
    letter-spacing: 5px;
    margin: -100px 0 150px;
}

.hero h2{
    font-size: 35px;
    letter-spacing: 4px;
    margin: -60px 0 60px;    
    line-height: 200%;
}

.hero h3{
    font-size: 20px;
    letter-spacing: 2px;
    margin: -60px 0 60px;    
}

.hero p{
    font-size: 150%;
    letter-spacing: 2px;
    margin: 0px 0 0px;    
}

/* .hero .container .footer */

.content h2, 
.content h3 {
    font-size: 150%;
    margin: 20px 0;
}

.content p {
    color: #555;
    line-height: 30px;
    letter-spacing: 1.2px;
}

/* .bottom { */
    /* align-items: center; */
    /* text-align: center; */
    /* position: relative; */
    /* font-size: 30px; */
/* } */