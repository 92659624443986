footer {
    background-color: #fff;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    padding: 30px 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
}
  
.footer-content {
  color:  rgba(255, 255, 255, 0.87);
}

  /* Light theme specific styles */
@media (prefers-color-scheme: light) {
  .footer {
      background-color: white; /* Light grey for light mode */
  }

  .footer-content {
    color: #000;
  }
}
  