/* Navbar.css */
#root[data-theme='dark'] {
    color-scheme: dark;
    --background-color: #242424; /* Dark background */
    --text-color: rgba(255, 255, 255, 0.87); /* Light text for dark background */
    --nav-footer-background: #333; /* Dark grey for navbar and footer */
    --hover-background-color: #555;
    --active-background-color: #666;
  }

nav {
    background-color: #fff;
    color: var(--text-color);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* This keeps the children (nav-left and nav-right) on opposite ends */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* .nav-left {
    flex: 1;
} */

.nav-right {
    display: flex;
    /* flex: 1; */
    justify-content: flex-end; 
    margin-right: 5%;
    font-size: large;
}

nav a {
    color: var(--text-color);
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    transition: background-color 0.3s;    
}

body {
    padding-top: 40px; /* Adjust as necessary based on your navbar's height */
}

nav a:hover {
    background-color:  #ddd;
}

.active {
    background-color: #ccc;
}

.nav-center {
    font-weight: bold;
    text-align: center;
    /* flex: 1; */
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
}

.dropdown-content a {
    color: var(--text-color);
    padding: 12px 8px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color:  #666;
}

.dropdown:hover .dropdown-content {
    display: block;
}

a {
    font-weight: 500;
    color: #007bff;
    text-decoration: inherit;
  }
  
  a:hover {
    color: rgb(189, 39, 39);
    font-weight: bold;
  }
